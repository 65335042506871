import React from 'react'
import PageBannerImgBackground from '../components/PageBannerImgBackground/pageBannerImgBackground'
import Layout from '../components/Layout'

 const PressKit = () => {
    return (
        <Layout
        title='Press Kit'
        description='TheIncLab assets for media use. Please review guidelines and use assets as needed.'
        >

        <div>
            <PageBannerImgBackground 
            imgPath='/img/press-kit-optimized.jpg'
            title='Press Kit'
            description='TheIncLab assets for media use. Please review guidelines and use assets as needed.'
            />
<div className="branding-content-wrapper">

<div className="row two-column-even">
    <div className="col-sm-6 col-xs-12  primary-logo-wrapper">
        <p>PRIMARY LOGO</p>
       
        <div className="img-box">
            <img className="primary-logo" src="/img/branding/TIL_Logo.png" />
            <div className="download-btn"> 
                <button>
                    <a download href="/img/branding/TIL_Logo.png">
                        <img src="/img/branding/download-button.png"/>
                    </a>
                </button>
            </div>
        </div>

        <div className="img-box">
            <img className="primary-logo"  src="/img/branding/til_logo_black_long.jpg" />
            <div className="download-btn"> 
                <button>
                    <a download href="/img/branding/til_logo_black_long.jpg">
                        <img src="/img/branding/download-button.png"/>
                    </a>
                </button>
            </div>
        </div>  
    </div>

    <div className="col-sm-6 col-xs-12 right">
        <p>VERTICAL LOGO</p>

        <div className="row vertical-logos-wrapper">
            
            <div className="sm-6 col-xs-12 img-box">
                <img className="vertical-logo" src="/img/branding/FullStackLogoBLACK.png" />
                <div className="download-btn"> 
                    <button>
                        <a download href="/img/branding/FullStackLogoBLACK.png">
                            <img src="/img/branding/download-button.png"/>
                        </a>
                    </button>
                </div>
            </div>

            <div className="sm-6 col-xs-12 img-box">
                <img className="vertical-logo" src="/img/branding/FullStackLogoB&W.jpg" />
                <div className="download-btn"> 
                    <button>
                        <a download href="/img/branding/FullStackLogoB&W.jpg">
                            <img src="/img/branding/download-button.png"/>
                        </a>
                    </button>
                </div>
            </div>

        </div>

        
    </div>
</div>


<div className="full-width-column branding-container">
    <p>LOGO ALTERNATIVES</p>
    <div className="row logo-alternatives-container">
        <div className="col-sm-6 col-xs-12 img-box">
            <img style={{width: '300px'}} src="/img/branding/TILblack.jpg" />
            <div className="download-btn"> 
                <button>
                    <a download href="/img/branding/TILblack.jpg">
                        <img src="/img/branding/download-button.png"/>
                    </a>
                </button>
            </div>
        </div>
        
        <div className="col-sm-6 col-xs-12 img-box">
            <img style={{width: '300px'}} src="/img/branding/TILlogoWhite.jpg" />
            <div className="download-btn"> 
                <button>
                    <a download href="/img/branding/TILlogoWhite.jpg">
                        <img src="/img/branding/download-button.png"/>
                    </a>
                </button>
            </div>
        </div>

    </div>
</div>


<div className="color-guides row">

    <div className="left-section col-sm-3 col-xs-12">
        <div className="color-guide-details">
            <div className="circle-box">
                <div style={{fontWeight: 'bold'}}>TIL BLUE</div>
                <div className="blue-circle"></div>
            </div>
            <div className="details">
                <div>CMYK: 76/15/13/00</div>
                <div>RGB: 000/166/203</div>
                <div>HEX: #00A6CB</div>
            </div>
        </div> 
    </div>

    <div className="right-section col-sm-9 col-xs-12">
        <div className="row">
            <div className="col-sm-4 col-xs-12 color-guide-details">
                <div className="circle-box">
                    <div style={{fontWeight: 'bold'}}>BLACK</div>
                    <div className="black-circle"></div>
                </div>
                <div className="details">
                    <div>CMYK: 100/100/100/100</div>
                    <div>RGB:100/100/100</div>
                    <div>HEX: #00000</div>
                </div>
            </div>

            <div className="col-sm-4 col-xs-12 color-guide-details">
                <div className="circle-box">
                    <div style={{fontWeight: 'bold'}}>GRAY</div>
                    <div className="gray-circle"></div>
                </div>
                <div className="details">
                    <div>CMYK: 51/42/43/07</div>
                    <div>RGB: 000/166/203</div>
                    <div>HEX: #828282</div>
                </div>
            </div>

            <div className="col-sm-4 col-xs-12 color-guide-details">
                <div className="circle-box">
                    <div style={{fontWeight: 'bold'}}>WHITE</div>
                    <div className="white-circle"></div>
                </div>
                <div className="details">
                    <div>CMYK: 00/00/00/00</div>
                    <div>RGB: 255/255/255</div>
                    <div>HEX: #FFFFFF</div>
                </div>
            </div>

        </div>
    </div>
</div>


<div className="row font-details-wrapper">
    <div className="font-details col-sm-12">
        <div>FONT: HELVETICA NEUE </div>
        <div className="letters">
            <div>ABCDEFGHIJKLMNOPQRSTUVWXYZ </div>
            <div> abcdefghijklmnopqrstuvwxyz</div> 
            <div>0123456789</div>   
        </div>
    </div>
</div>



<div className="minimums row">
    <div className="left-section col-sm-6 col-xs-12">
        <div><p>MINIMUM SIZE</p></div>
        <div className="row ">
            <div className="img-container col-sm-6 col-xs-12">
                <img style={{width: '250px', height: '150px'}} src="/img/branding/minimum-size-1.jpg" />
            </div>
            <div className="img-container col-sm-6 col-xs-12">
                <img style={{width: '150px'}} src="/img/branding/minimum-size-2.jpg" />
            </div>
        </div>
    </div>

    <div className="right-section col-sm-6 col-xs-12">
        <div><p>MINIMUM CLEAR SPACEE</p></div>
        <div className="img-container">

            <img style={{width: '300px'}} src="/img/branding/minimum-clearence.jpg" />
        </div>
   </div>
</div>



<div className="logo-misuse">
    <div style={{marginTop: '20px'}}><p>LOGO MISUSE</p></div>

    <div className='logo-column-items'>

    <div className="misuse-img-container">
        <div className="img-box">
            <img src="/img/branding/no_square_alone.jpg" />
            <p>TIL SQUARE ALONE</p>
        </div>
        
    </div>

    <div className="misuse-img-container">
        <div className="img-box">
            <img style={{width: '250px'}} src="/img/branding/no_opacity_v2.jpg" />
            <p>OPACITY CHANGE OF IMAGE</p>
        </div>
        
    </div>

    <div className="misuse-img-container">
        <div className="img-box">
            <img src="/img/branding/no_proportions.jpg" />
            <p>LOGO PROPORTIONS</p>
        </div>
        
    </div>

    <div className="misuse-img-container">
        <div className="img-box">
            <img src="/img/branding/no_changing_logo.jpg" />
            <p>CHANGING LOGO</p>
        </div>
    </div>

    </div>

    </div>



</div>


        </div>
        </Layout>

    )
}

export default PressKit
